
import HomePage from '../pages/home.f7.html';
import APakPage from '../pages/apak.f7.html';
import ALapinPage from '../pages/alapin.f7.html';
import NSmaginPage from '../pages/nsmagin.f7.html';
import EKarimovPage from '../pages/ekarimov.f7.html';

var routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/apak',
        component: APakPage,
    },
    {
        path: '/alapin',
        component: ALapinPage,
    },
    {
        path: '/nsmagin',
        component: NSmaginPage,
    },
    {
        path: '/ekarimov',
        component: EKarimovPage,
    },
];

export default routes;