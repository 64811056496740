/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component() {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`

    <div class="page single single-2 no-navbar" data-name="apak">
        <div class="page-content">
            <div class="single-header">
                <img class="single-header-image" src="static/img/riggif.gif" style="height:200px;"/>
                <div class="single-header-infos">
                    <div class="single-header-details">
                        <img src="https://ogkgroup.ru/assets/desktop/images/ogk-logo-ru-white.svg" style="width: 170px;display: inline-block;"/>
                    </div>
                </div>
            </div>

            <div class="block" style="margin-top:30px;">

                <div class="myshadow">
                <div class="author-block">
                    <a href="/author/" class="link">
                        <img src="https://ogkgroup.ru/storage/employee/image/1/8B3tF30gHc5q9lXn.jpeg" alt=""/>
                        <div class="author-infos">
                            <div class="author-name">Лапин Андрей Валерьевич</div>
                            <div class="author-description">Генеральный директор</div>
                        </div>
                    </a>
                </div>
                <a href="https://vcard.ogkgroup.ru/vcards/YWxhcGlu.vcf" target="_self" class="button big-button link facebook external" style="background:#009a67;border-top-right-radius: 0;border-top-left-radius: 0;"><i class="f7-icons">person_crop_circle_badge_plus</i>Добавить в контакты</a>
                </div>

                <div class="title-medium-container">
                    <h2></h2>
                </div>

                <div class="social-buttons">
                    <a href="https://ogkgroup.ru" target="_blank" class="big-button link facebook external" style="background-color:#009a67;"><i class="f7-icons">globe</i>ogkgroup.ru</a>
                    <a href="https://t.me/ogkgroup" target="_blank" class="big-button link facebook external"><i class="f7-icons">paperplane_fill</i>Telegram</a>
                    <!--<a href="https://www.facebook.com/OGKGroup/" target="_blank" class="big-button link facebook external"><i class="f7-icons">paperplane_fill</i>Telegram</a>-->
                    <!--<a href="https://www.instagram.com/ogk_group/" target="_blank" class="big-button link twitter external" style="background:#0095f6;"><i class="f7-icons">logo_instagram</i>Instagram</a>-->
                </div>
                <div class="social-buttons" style="margin-top: 10px;">
                    <a href="https://ogkgroup.ru/storage/files/ogk_group_pre_april_29042022_final.pdf" target="_blank" class="big-button link facebook external bg-color-deeporange"><i class="f7-icons">doc_text_fill</i>Презентация</a>
                    <a href="https://player.vimeo.com/video/371448432" target="_blank" class="big-button link facebook external bg-color-red"><i class="f7-icons">play_rectangle_fill</i>Видео о нас</a>
                </div>

                <div class="title-medium-container">
                    <h2></h2>
                </div>
                <div class="single-header-details">
                    <div class="post-category" id="drilled" style="color: #009a67;">0.00</div>
                    <div class="post-date">Пробурено п.м. с момента открытия этой страницы</div>
                </div>
            </div>

        </div>
    </div>
`
    }
    ;
}

framework7Component.id = 'd2bcc364fa';
export default framework7Component;