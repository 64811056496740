/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component() {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">

  <!-- Your main view, should have "view-main" class -->
  <div class="view view-main view-init safe-areas" data-url="/"></div>

  </div>
`
    }
    ;
}

framework7Component.id = 'd399880bfe';
export default framework7Component;