import $ from 'dom7';
import Framework7 from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';


// Import Routes
import routes from './routes.js';
// Import main app component
import App from '../app.f7.html';


var app = new Framework7({
  name: 'nfc', // App name
  // theme: 'auto', // Automatic theme detection
  theme: 'ios', // Automatic theme detection
  el: '#app', // App root element
  component: App, // App main component
  routes: routes,
    view:{pushState:false,reloadPages:false},
});

function ruNumFormat (number,dec) {
    if(number=='-'){
        return "-";
    }
    var decimals = (dec)?dec:'0';
    var dec_point = ',';
    var thousands_sep = ' ';

    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    var numFormatted = s.join(dec);
    return numFormatted;
}

$(document).on('page:beforein', '.page[data-name="home"]', function (e,p) {
    if(p.route.hash=='apak'){
        app.views.main.router.navigate('/apak',{ transition: 'f7-dive', clearPreviousHistory: true });
    }
    else if(p.route.hash=='alapin'){
        app.views.main.router.navigate('/alapin',{ transition: 'f7-dive', clearPreviousHistory: true });
    }
    else if(p.route.hash=='ekarimov'){
        app.views.main.router.navigate('/ekarimov',{ transition: 'f7-dive', clearPreviousHistory: true });
    }
    else if(p.route.hash=='nsmagin'){
        app.views.main.router.navigate('/nsmagin',{ transition: 'f7-dive', clearPreviousHistory: true });
    }
    var drilled = 0;
    setInterval(function () {
        drilled += 0.0008;
        $('#drilled').html(ruNumFormat(drilled,3));
    },50);
});
$(document).on('page:init', '.page[data-name="apak"]', function (e,p) {
    var drilled = 0;
    setInterval(function () {
        drilled += 0.0008;
        $('#drilled').html(ruNumFormat(drilled,3));
    },50);
});

